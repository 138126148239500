import React from 'react';
import styled from 'styled-components';

const StyledStops = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title Title' max-content
    'Filters Filters' max-content
    '. ClearFilter'
    'Table Table' max-content
    '. Pagination' 45px;
  grid-row-gap: 25px;
  width: max-content;
  max-width: 90vw;
  margin: 55px auto;

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-template:
      'Icon' 25px
      'Title' max-content
      'Filters' max-content
      'Drop' max-content
      'Table' max-content
      'Pagination' 45px;
    max-width: 90vw;
    margin: 55px auto;
  }
`;

export const ClearFilter = styled.span`
  cursor: pointer;
  display: grid;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  width: 100px;
  grid-area: ClearFilter;
  grid-template-columns: max-content max-content;
  gap: 5px;
  color: white;
  background-color: #788195;
  padding: 3px;
  border-radius: 4px;
  justify-self: end;
  justify-content: center;
  align-items: center;

  @media (max-width: 660px) {
    margin-top: 20px;
    justify-self: flex-start;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.table.border};
  }
`;

type Props = {
  children: React.ReactNode;
};
export const Stops: React.FC<Props> = ({ children }) => <StyledStops>{children}</StyledStops>;
