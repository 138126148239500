import { AttributeType } from '../components/ui/HistoryTable';

type Table = { [key: string]: string | number | boolean | null }[];

type Matrix = (string | number | undefined | null | boolean)[][];

/**
 * It transposes the matrix by swapping rows by columns.
 * @param matrix A regular dense 2-dimensional matrix of numbers or strings
 */
export function transpose(matrix: Matrix): Matrix | undefined {
  return matrix[0]?.map((_col, i) => matrix.map((row) => row[i]));
}

/**
 * Transforms a matrix that is in the format matrix[row][column] into a matrix
 * in the format matrix[column][row], also transforming into a csv multiline
 * string.
 * @param matrix A matrix in the format matrix[row][column]
 * @returns Transform rows into columns and pass to CSV format as text
 */
export function makeCSV(matrix: Matrix | undefined): string | undefined {
  if (!matrix) return undefined;
  return transpose(matrix)
    ?.map((row) =>
      row
        .map((value) => {
          let stringValue = value?.toString();
          if (stringValue?.includes(',') || stringValue?.includes('\n')) {
            stringValue = '"' + stringValue + '"';
          } 

          return stringValue;
        })
        .join(',')
    )
    .join('\n');
}

/**
 * Transforms a table that is in the format [{attr: value}] into a matrix
 * in the format matrix[column][row], also transforming into a csv multiline
 * string.
 * @param attributes An array from attributes in the format [{name: column, attr: string}]
 * @param table A table in the format [{attr: value}]
 * @returns Transform rows into columns and pass to CSV format as text
 */
export function makeCSVFromTable(attributes: Omit<AttributeType, 'dataCy'>[], table: Table): string | undefined {
  if (!table) return undefined;
  return makeCSV(attributes.map((attr) => [attr.name, ...table.map((value) => value[attr.attr])]));
}

/**
 * @param fileName File name with the format to be downloaded
 * @param data File in text format
 */
export function downloadFile(fileName: string, data?: string): void {
  if (!data) return;
  const element = document.createElement('a');
  const file = new Blob(['\uFEFF' + [data]], {
    type: 'text/csv; charset=utf-18',
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
